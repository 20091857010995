import React from "react";

export default function ProductBadgeGift({product}: any) {
    if (!product.giftBadge) return <></>

    return (
        <div className={"product-badge bg-tertiary me-2"}>
                <span>
                  <i className="fa-sharp fa-solid fa-gift text-white"/>
                </span>
        </div>
    )
}