import React from "react";

interface Props {
    total?: number;
    rating: number;
    stars?: number;
    hasDecimal?: boolean;
    link?: boolean;
    showRating?: boolean;
    showTotal?: boolean;
    className?: string;
}

export default class RatingStar extends React.PureComponent<Props> {
    total: number;
    rating: number;
    stars: number;
    hasDecimal: boolean;
    link: boolean;
    showRating: boolean;
    className: string;

    constructor(props: any, context: any) {
        super(props, context);
        this.total = props.total;
        this.rating = props.rating;
        this.link = props.link;
        this.stars = Math.floor(props.rating);
        this.hasDecimal = (this.rating - this.stars) > 0;
        this.showRating = this.props.showRating ?? true;
        this.className = this.props.className ?? 'mb-3'
    }

    getStars() {
        let stars = [...Array(this.stars)].map((e, i: number) => <i key={`star-${i}`} className="fa-solid fa-star"/>);
        if (this.hasDecimal) stars.push(<i key={`star-6`} className="fa-solid fa-star-half-stroke"/>);
        return stars;
    }

    getRating() {
        return (
            <>
                {this.showRating ? <span className={"me-1"}>{this.rating}</span> : <></>}
                {this.props.showTotal ? <span>({this.total})</span> : <></>}
            </>
        )
    }

    render() {
        if (!this.props.rating) return <div className={`rating-icons`}></div>

        return (
            <>
                <div className={`rating-icons ${this.className}`}>
                    {this.stars > 0 ? (
                        <>
                            {this.link ? (
                                <a href={'#reviewSection'} aria-label={'Rating anchor link'}>
                                    {this.getStars()}
                                </a>
                            ) : (
                                <>{this.getStars()}</>
                            )}
                        </>
                    ) : <></>}

                    {(this.total !== null && this.total > 0) && (
                        <span className="ms-2">
              {this.link ? (
                  <a href={'#reviewSection'} aria-label={'Rating anchor link'}>
                      {this.getRating()}
                  </a>
              ) : (
                  <>{this.getRating()}</>
              )}
            </span>
                    )}
                </div>
            </>
        )
    }
}
