class MainVariantService {
    getMain(variants: any, iteration: number = 0): any {
        const minimumStock = this.getMinimumStock(variants)

        try {
            const variant = iteration === 0 ? variants.find((v: any) => v.isMain === v.id) : variants[iteration];
            if (!variant) return variant[0];
            if (variant.isExpring == 1 && variant.stock <= 0) return this.getMain(variants, iteration + 1);
            if (variant.lastStock == 1 && variant.stock <= 0) return this.getMain(variants, iteration + 1);
            if (variant.lastStock == 1 && variant.stock <= minimumStock) return this.getMain(variants, iteration + 1);
            if (variant.stock <= 0) return this.getMain(variants, iteration + 1);
            return variant;
        } catch (e) {
            return variants[0];
        }
    }

    getMinimumStock(variants: any) {
        try {
            return Math.min(variants[0].minimumStock, variants[0].supplierMinimumStock, Number(process.env.NEXT_PUBLIC_PRODUCT_MINIMUM_STOCK));
        }
        catch(e) {
            return Number(process.env.NEXT_PUBLIC_PRODUCT_MINIMUM_STOCK)
        }
    }
}

const mainVariantService = new MainVariantService();
export default mainVariantService;