import React from "react";
import {setLists, setOrderNumber} from "../../stores/wishlist/wishlistSlice";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import apiClient from "../../utils/apiClient";
import messageService from "../../services/message/messageService";
import wishlistService from "../../services/wishlistService";
import i18next from "i18next";

export default function ProductWishlist(props: any) {
    const orderNumbers = useSelector((state: State) => state.wishlistState.orderNumbers);
    const dispatch = useDispatch();
    const product = props.product;

    async function addItem() {
        dispatch(setOrderNumber(product.orderNumber));
        const sessionId: string = await wishlistService.cookie();

        try {
            await apiClient.post('/api/wishlist/item/add', {sessionId, orderNumber: product.orderNumber});
            messageService.success(i18next.t('wishlist.added').toString(), dispatch);
            const data = await wishlistService.load();
            dispatch(setLists(data));
        } catch (e) {
            console.error(e);
            messageService.error(i18next.t('wishlist.addFailed').toString(), dispatch);
        }
    }

    return (
        <button type="button"
                name={"btn-wishlist-add"}
                onClick={() => addItem()}
                aria-label={"Wishlist"}
                disabled={(product.lastStock && product.stock <= 0)}
                className={`btn btn-alt w-100 pt-1 pb-1 bx-narrative-button ${props.height && 'h-100'}`}>
            <i className={`fa-heart ${orderNumbers.includes(product.orderNumber) ? 'fa-solid' : 'fa-regular'}`}
               style={{height: '14px'}}/>
        </button>
    );
}
