import React, {useRef, useState} from "react";
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ProductDeliverStatus from "../DeliveryStatus";
import mainVariantService from "../../../services/product/MainVariantService";

export default function ProductVariantSelectors({variants, onVariantChange, product, $this}: any) {
    if (!variants) return <></>

    const mainVariant = mainVariantService.getMain(variants);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [selected, setSelected] = useState(mainVariant);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
        variant: any
    ) => {
        setSelectedIndex(index);
        setOpen(false);
        onVariantChange(variant.id, $this);
        setSelected(variant);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function validate() {
        if (!variants.length) return false;
        if (variants.length === 1 && (!variants[0].size || !variants[0].size.length)) return false;
        return !(variants.length === 1 && !selected);
    }

    if (!validate()) return (
        <Button onClick={handleToggle}
                color={"primary"}
                variant={"outlined"}
                fullWidth
                disabled
                sx={{
                    borderColor: "#000",
                    borderRadius: "6px",
                    '&:hover': {
                        borderColor: "#000",
                    }
                }}>
            <div className={"d-flex flex-row justify-content-center align-items-center"}>
                <ProductDeliverStatus lineItem={selected} className={"ms-2"}/>
            </div>
        </Button>
    )

    return (
        <>
            <div ref={anchorRef} className={"position-relative"}>
                <Button onClick={handleToggle}
                        color={"primary"}
                        variant={"outlined"}
                        fullWidth
                        disabled={variants.length === 1}
                        sx={{
                            borderColor: "#000",
                            borderRadius: "6px",
                            '&:hover': {
                                borderColor: "#000",
                            }
                        }}>
                    <div className={"d-flex flex-row justify-content-center align-items-center"}>
                        <span className={"text-lowercase"}>{selected ? selected.size : ''}</span>
                        <ProductDeliverStatus lineItem={selected} className={"ms-2"}/>
                    </div>
                    {variants.length > 1 ? (
                        <ArrowDropDownIcon className={"position-absolute top-0 end-0 me-2 mt-1 pt-1"}
                                           sx={{height: "25px", width: "25px"}}/>
                    ) : <></>}
                </Button>
            </div>
            {variants.length > 1 ? (
                <Popper
                    sx={{
                        zIndex: 1,
                        width: "100%"
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}>
                            <Paper sx={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "1px solid rgba(0, 0, 0, 0.1)"
                            }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {variants.map((variant: any, index: any) => (
                                            <MenuItem
                                                key={variant.id}
                                                selected={variant.id === selected.id}
                                                sx={{textAlign: "center", display: "block"}}
                                                onClick={(event) => handleMenuItemClick(event, index, variant)}>
                                                <div className={"d-flex flex-row justify-content-center align-items-baseline"}>
                                                    <span className={"text-lowercase"}>{variant.size}</span>
                                                    <ProductDeliverStatus lineItem={variant} className={"ms-2"}/>
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            ) : <></>}
        </>
    )
}