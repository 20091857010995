import {deleteCookie, getCookie, setCookie} from "cookies-next";
import cookieOptions from "../../utils/cookieOptions";

class LocalStorageService {
    setItem(key: string, value: any, cookieOptions?: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            setCookie(key, value, cookieOptions);
        }
    }

    getItem(key: string): any {
        try {
            return JSON.parse(localStorage.getItem(key)!);
        } catch (e) {
            return getCookie(key);
        }
    }

    deleteItem(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            deleteCookie(key, cookieOptions(new Date(), 0));
        }
    }
}

const localStorageService: LocalStorageService = new LocalStorageService();
export default localStorageService