import React from "react";
import {useDispatch} from "react-redux";
import {Dispatch} from "@reduxjs/toolkit";
import apiInternal from "../../utils/apiInternal";
import {getCookie, setCookie} from "cookies-next";
import {v4 as uuidv4} from 'uuid';
import i18next from "i18next";
import messageService from "../../services/message/messageService";

interface Props {
    product: any
}

export default function ProductCompare({product}: Props) {
    const dispatch: Dispatch = useDispatch();

    function getSessionId() {
        let sessionId = getCookie('compareId');
        if (!sessionId) {
            sessionId = uuidv4();
            setCookie('compareId', sessionId);
        }
        return sessionId;
    }

    async function add() {
        try {
            await apiInternal().post('/api/v2/product/compare/add', {
                sessionId: getSessionId(),
                product,
                locale: i18next.language
            });
        } catch (e) {
            return;
        }

        messageService.success(`${product.name} wurde zur Vergleichsliste hinzugefügt`, dispatch);
    }

    return (
        <button type="button"
                name={"btn-product-compare-add"}
                onClick={() => add()}
                className={"btn btn-alt w-100 pt-1 pb-1 bx-narrative-button h-100"}
                aria-label={"Product compare"}>
            <i className={"fa-solid fa-code-compare"} style={{height: '14px'}}/>
        </button>
    );
}
