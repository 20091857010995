import axios from "axios";
import {getCookies} from "cookies-next";

class ApiServer {
    getClient(token: string, cookies?: any) {
        let config: any = {
            baseURL: process.env.NEXT_PUBLIC_API_URL,
            // withCredentials: true,
            headers: {
                Authorization : `Bearer ${token}`,
                // Cookie: `sessionId=${this.getCookie('sessionId=')};sessionId=${this.getCookie('sessionId=')};`
                Cookie: this.getRequiredCookies()
            }
        };

        if (cookies) config.headers.Cookie = this.parseCookies(cookies);
        return axios.create(config);
    }

    async post(path: string, token: string, data?: any, cookies?: any) {
        return this.getClient(token, cookies).post(path, data)
    }

    parseCookies(cookies: any) {
        try {
            return `sessionId=${cookies.sessionId};`;
        }
        catch(e) {
            return '';
        }
    }

    getRequiredCookies(): string {
        const cookies: string[] = ['sessionId', 'wishlist', 'lastOrder', 'keepLogin', 'customer', 'sanctumToken'];
        let str: string = '';
        cookies.map((cookie: string) => {
            str += `${cookie}=${this.getCookie(`${cookie}=`)};`;
        });
        return str;
    }

    getCookie(name: string) {
        try {
            const ck: string = getCookies().toString()
            if (!ck.includes(name)) return '';
            const list: any[] = ck.split(';');
            const item: any = list.find((a: any) => a.includes(name));
            const split = item.split('=');
            return decodeURI(split[1]);
        }
        catch(e) {
            console.warn('-- failed to get access token cookie', e);
            return '';
        }
    }
}

const apiServer = new ApiServer();
export default apiServer