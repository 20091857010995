import axios from "axios";

class SpClient {
  client: any;
  regionPath = 'search/?dataset=plz_verzeichnis_v2&lang=de&facet=postleitzahl&facet=kanton&q=';

  constructor() {
    this.client = axios.create({
      baseURL: 'https://swisspost.opendatasoft.com/api/records/1.0/',
    });
  }

  get(value: string) {
    return this.client.get(value);
  }
}

const spClient = new SpClient();
export default spClient