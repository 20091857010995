import React from "react";
import ProductBadgeNew from "./badge/New";
import ProductBadgeDiscount from "./badge/Discount";
import ProductBadgeGift from "./badge/Gift";
import ProductBadgeTopSeller from "./badge/TopSeller";
import ProductBadgeColorCard from "./badge/ColorCard";
import ProductBadgePharma from "./badge/Pharma";

export default function ProductBadge({product, listing = true}: any) {
    const style = listing ? {minHeight: "40px"} : {};

    return (
        <>
            <div className={"badge-container mt-2 d-flex flex-row"} style={style}>
                <ProductBadgeDiscount product={product}/>
                <ProductBadgeGift product={product}/>
                <ProductBadgeNew product={product}/>
                <ProductBadgeTopSeller product={product}/>
                <ProductBadgeColorCard product={product}/>
                <ProductBadgePharma product={product} listing={true}/>
            </div>
        </>
    )
}