interface Props {
    lineItem?: any,
    className?: string
}

export default function ProductDeliverStatus({lineItem, className}: Props) {
    if (!lineItem || lineItem.mode) return <></>;

    return (
        <span className={className}>
            {(() => {
                if (lineItem.stock > 0) {
                    return (
                        <i className="fa-sharp fa-solid fa-circle text-green delivery-status-icon"></i>
                    );
                } else if (lineItem.releaseDate && (new Date(lineItem.releaseDate) > new Date())) {
                    return (
                        <i className="fa-sharp fa-solid fa-circle text-red delivery-status-icon"></i>
                    )
                } else if (lineItem.lastStock && lineItem.stock <= 0) {
                    return (
                        <i className="fa-sharp fa-solid fa-circle text-red delivery-status-icon"></i>
                    );
                } else {
                    return (
                        <i className="fa-sharp fa-solid fa-circle text-orange delivery-status-icon"></i>
                    );
                }
            })()}
        </span>
    )
}
