import apiClient from "../../utils/apiClient";
import apiServer from "../../utils/apiServer";

class PerfectPointClientService {
  async getAvailablePoints() {
    if (!process.env.NEXT_PUBLIC_PERFECTPOINT) return;

    try {
      return await apiClient.post('/api/point/available')
    }
    catch(e) {
      console.error('perfect point available error', e);
      return false;
    }
  }

  async getAvailablePointsAccount(serverSide: boolean = false, token: string = '') {
    if (!process.env.NEXT_PUBLIC_PERFECTPOINT) return;

    try {
      return await apiServer.post('/api/point/available/account', token);
    }
    catch(e) {
      console.error('perfect point available error', e);
      return false;
    }
  }

  async getHistory(token: string) {
    if (!process.env.NEXT_PUBLIC_PERFECTPOINT) return;

    try {
      return await apiServer.post('/api/point/history', token)
    }
    catch(e) {
      console.error('perfect point history error', e);
      return false;
    }
  }
}

const perfectPointClientService = new PerfectPointClientService();
export default perfectPointClientService