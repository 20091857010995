import apiClient from "../utils/apiClient";
import perfectPointClientService from "./perfectPoint/perfectPointClientService";
import {getCookie} from "cookies-next";
import i18next from "i18next";
import {locale} from "../utils/locale";
import cookieService from "./cookie/cookieService";

class CartService {
  async getOffcanvasCart() {
    const [resCart] = await Promise.all([
      this.requestCart(),
    ]);
    return {
      cart: resCart.data,
    }
  }

  async getCart(shippingMethodId?: number|null, paymentMethodId?: number|null, updatePrice: boolean = false) {
    const [resCart, resPremiums, resPremiumVisible] = await Promise.all([
      this.requestCart(shippingMethodId, paymentMethodId, updatePrice),
      this.requestPremiums(),
      this.requestPremiumVisible()
    ]);
    return {
      cart: resCart.data,
      premiums: resPremiums.data,
      premiumVisible: resPremiumVisible.data
    }
  }

  async getCustomer() {
    const {data} = await this.requestCustomer();
    return data;
  }

  async getConfirm(shippingMethodId?: number, paymentMethodId?: number) {
    const [resCart, resPerfectPoint]: any = await Promise.all([
      this.requestCart(shippingMethodId, paymentMethodId),
      perfectPointClientService.getAvailablePoints(),
    ]);

    return {
      cart: resCart.data,
      perfectPoint: resPerfectPoint ? resPerfectPoint.data : null,
    }
  }

  getCustomerId() {
    try {
      const customer: any = getCookie('customer');
      return JSON.parse(customer).id;
    }
    catch(e) {
      return null;
    }
  }

  async addToCart(orderNumber: string, quantity: number = 1) {
    return apiClient.post('/api/cart/add', {orderNumber: orderNumber, quantity: quantity, userId: this.getCustomerId()});
  }

  async addItemsToCart(id: number) {
    return apiClient.post('/api/wishlist/cart/add/items', {id: id});
  }

  async removeItem(lineItem: any) {
    return apiClient.post('/api/cart/remove', {id: lineItem.id, orderNumber: lineItem.orderNumber, userId: this.getCustomerId()});
  }

  async changeQuantity(id: number, quantity: number = 1) {
    return apiClient.post('/api/cart/quantity', {id: id, quantity: quantity, userId: this.getCustomerId()})
  }

  requestCart(shippingMethodId?: number|null, paymentMethodId?: number|null, updatePrice: boolean = false) {
    return apiClient.post('/api/cart', {
      joinPrice: true,
      userId: this.getCustomerId(),
      languageId: locale(i18next.language),
      shippingMethodId, paymentMethodId,
      customerGroup: cookieService.getCustomerGroup(),
      updatePrice
    });
  }

  requestCustomer() {
    return apiClient.post('/api/customer/data');
  }

  requestPremiums() {
    return apiClient.post('/api/premium/list', {languageId: locale(i18next.language)});
  }

  requestPremiumVisible() {
    return apiClient.post('/api/premium/visible');
  }
}

const cartService = new CartService();
export default cartService