import {Swiper} from "swiper/types";
import FsLightbox from "fslightbox-react";
import ReactDOM from "react-dom";

function getAttributes(orderNumber: string, name: string, className: string) {
    return {
        className,
        "data-bx-narrative-type": "pdp",
        "data-bx-narrative-field": "sku",
        "data-bx-narrative-value": orderNumber,
        "data-bx-narrative-name": name,
    };
}

export function getContainerAttributes(orderNumber: string, name: string, groupBy: string, className: string = "") {
    return {
        ...getAttributes(orderNumber, name, `${className} bx-narrative`),
        "data-bx-narrative-group-by": groupBy,
    };
}

export function getSingleAttributes(orderNumber: string, name: string, itemId: string, className: string = "") {
    return {
        ...getAttributes(orderNumber, name, `${className} bx-narrative-item bx-narrative-root-item`),
        "data-bx-item-id": itemId
    };
}

function addClasses(el: Element) {
    el.classList.add('bx-narrative-item', 'bx-narrative-button');
}

export function addLightboxAttributes(instance: FsLightbox) {
    const domNode = ReactDOM.findDOMNode(instance);
    if (!(domNode instanceof Element)) {
        return;
    }

    const prev = domNode.querySelector('·fslightbox-slide-btn-previous-container');
    if (prev instanceof HTMLElement) {
        addClasses(prev);
        prev.dataset.bxItemId = 'lightboxArrow|dir=left';
    }

    const next = domNode.querySelector('.fslightbox-slide-btn-next-container');
    if (next instanceof HTMLElement) {
        addClasses(next);
        next.dataset.bxItemId = 'lightboxArrow|dir=right';
    }

    const toolbarButtons = domNode.querySelectorAll('.fslightbox-toolbar-button');
    const mutationObserver = new MutationObserver((changes) => {
        for (const { target } of changes) {
            if (target instanceof HTMLElement) {
                target.dataset.bxItemId = `lightboxButton${target.dataset.index}|title=${target.title}`;
            }
        }
    });
    const observerOptions = { attributes: true, attributeFilter: ['title'] };
    toolbarButtons.forEach((button, index) => {
        if (!(button instanceof HTMLElement)) {
            return;
        }
        addClasses(button);
        button.dataset.index = String(index);
        button.dataset.bxItemId = `lightboxButton${index}|title=${button.title}`;
        mutationObserver.observe(button, observerOptions)
    });
}

export function addSwiperAttributes({navigation: {prevEl, nextEl}, pagination,}: Swiper) {
    if (prevEl) {
        addClasses(prevEl);
        prevEl.dataset.bxItemId = 'arrow|dir=left';
    }
    if (nextEl) {
        addClasses(nextEl);
        nextEl.dataset.bxItemId = 'arrow|dir=right';
    }
    pagination.bullets.forEach((el, index) => {
        addClasses(el);
        el.dataset.bxItemId = `bullet|index=${index}`;
    })
}

interface BxAttribute {
    name: string;
    value: string;
}

interface Block {
    'bx-attributes'?: BxAttribute[];
}

export function convertBxAttributes(block: Block, className: string = '') {
    const attributes: Record<string, string> = {className};
    block['bx-attributes']?.forEach(({name, value}) => {
        if (name === 'class') {
            attributes.className += ` ${value}`;
        } else {
            attributes[name] = value;
        }
    });
    return attributes;
}
