import React from "react";
import colorCardParser from "../../../services/product/colorCardParser";

export default function ProductBadgeColorCard({product}: any) {
    if (!product.colorCardId || !product.colorCardArticles) return <></>;
    const articles = colorCardParser.parse(product.colorCardArticles);
    const products = [...articles];
    const shortCut = products.splice(0, 1);

    function isBright(hex: string) {
        if (!hex || !hex.length) return true;
        try {
            const rgb = (hex: any) => `${hex.match(/\w\w/g).map((x: any) => +`0x${x}`)}`;
            const c = rgb(hex).split(',');
            for (const i of c) {
                if (parseInt(i) < 220) return false;
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    function getStyle(product: any, index: number) {
        const s: any = {left: index > 0 ? '-25px' : 0};
        product.pfh_color_image ? s.background = `url(${product.pfh_color_image})` : s.backgroundColor = product.pfh_color_card_hex;
        return s;
    }

    return (
        <div className={"color-card-badge me-2 d-flex flex-row position-relative"}>
            {shortCut.map((product: any, index: number) => (
                <div key={product.id}
                     data-bg-img={product.pfh_color_image}
                     data-bg-color={product.pfh_color_card_hex}
                     className={`color-card-item position-relative ${isBright(product.pfh_color_card_hex) ? 'border no-hover' : ''}`}
                     style={getStyle(product, index)}/>
            ))}
            {articles.length > 2 && (
                <div
                    className={`position-absolute start-0 end-0 text-center fw-bold ${(!isBright(shortCut[0].pfh_color_card_hex)) && 'text-white'}`}
                    style={{left: "-30px"}}>
                    +{articles.length}
                </div>
            )}
        </div>
    )
}
