export const breakpointsDesc = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'] as const;
export type Breakpoint = typeof breakpointsDesc[number];

const breakpointWidths: Record<Breakpoint, number> = {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0
} as const;

export function getWidth(breakpoint: Breakpoint) {
    return breakpointWidths[breakpoint];
}
