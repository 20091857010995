import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import {openCart} from "../../stores/cart/cartSlice";
import cartService from "../../services/cartService";
import tagManager from "../../services/tagManager";
import apiClient from "../../utils/apiClient";

function ProductAddToCart({product, wide, width = 'w-100', height = 'h-100', listing, className}: any) {
    const quantity = useSelector((state: State) => state.productSelector.quantity);
    const dispatch = useDispatch();

    async function addToCart() {
        try {
            // await apiClient.get('/sanctum/csrf-cookie');
            await cartService.addToCart(product.orderNumber, listing ? 1 : quantity);
            const p = {...product};
            p.quantity = quantity;
            tagManager.addToCart([product], quantity);
            dispatch(openCart());
        } catch (e) {
            console.error('-- add to cart failed', e);
        }
    }

    return (
        <button type="button"
                className={`bx-basket-add btn btn-black position-relative ${height} ${width} ${!wide && 'pt-1 pb-1'} ${className}`}
                aria-label={"Add to cart"}
                onClick={() => addToCart()}
                disabled={(product.lastStock && product.stock <= 0)}>
            <i className="fa-solid fa-cart-plus text-white position-absolute top-50 start-50 translate-middle"></i>
        </button>
    )
}

export default ProductAddToCart
