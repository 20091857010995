import router from 'next/router';
import React from 'react';
import NextLink from 'next/link';

type Props = React.ComponentPropsWithoutRef<'a'> & React.ComponentPropsWithoutRef<typeof NextLink> & {
    name?: string;
    scroll?: boolean;
    disableLink?: boolean;
    shallow?: boolean;
    href: string;
    scrollRef?: React.RefObject<HTMLElement>;
    apex?: boolean;
    noFollow?: boolean;
    noIndex?: boolean;
    query?: any
};

export default function Link(
    {
        href,
        scroll = true,
        scrollRef,
        disableLink = false,
        children,
        prefetch,
        target = '',
        shallow = false,
        passHref = false,
        onClick,
        apex = false,
        noFollow = false,
        noIndex = false,
        query,
        ...otherProps
    }: Props) {
    let rel: string = '';
    if (noIndex) rel += 'noindex ';
    if (noFollow) rel += 'nofollow ';
    if (rel.length) otherProps = {...otherProps, rel}

    async function clickHandler(e: React.MouseEvent<HTMLAnchorElement>) {
        if (apex) href = `${process.env.NEXT_PUBLIC_APP_URL}${href}`;

        if (onClick) onClick(e);

        if (e.ctrlKey || e.metaKey) {
            target = '_blank';
            return;
        }
        if (target === '_blank') return;

        e.preventDefault();
        if (disableLink) return;

        try {
            if (scrollRef) scrollRef.current?.scrollIntoView({behavior: 'smooth', inline: 'start'});
            await router.push(href, undefined, {scroll: scroll, shallow: shallow});
        } catch (e) {
            console.error('-- revision changed, full page load')
            location.href = href;
        }
    }

    return (
        <a href={href}
           onClick={clickHandler}
           target={target}
           aria-label={'Link'}
           {...otherProps} >
            {children}
        </a>
    );
}
