class ImageService {
  getImage(imgUrls: any, orderNumber: string) {
    try {
      return JSON.parse(imgUrls).original.url;
    }
    catch(e: any) {
      return `https://www.perfecthair.ch/media/image/${orderNumber}.jpg`
    }
  }

  getCoverImage(product: any) {
    try {
      if (product.cover.length) return this.getImage(product.cover[0].imagesUrls, product.orderNumber);
      return this.getImage(product.images[0].imagesUrls, product.orderNumber);
    }
    catch(e) {
      return `https://www.perfecthair.ch/media/image/${product.orderNumber}.jpg`
    }
  }
}

const imageService = new ImageService();
export default imageService