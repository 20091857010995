import {enableError, enableSuccess, setShow, showWarning} from "../../stores/message/messageSlice";

class MessageService {
  success(message: any, dispatch: any, autoHide: boolean = true, delay?: number) {
    dispatch(enableSuccess(message));
    this.hide(autoHide, dispatch, delay);
  }

  error(message: any, dispatch: any, autoHide: boolean = true, delay?: number) {
    dispatch(enableError(message));
    this.hide(autoHide, dispatch, delay);
  }

  warning(message: any, dispatch: any, autoHide: boolean = true, delay?: number) {
    dispatch(showWarning(message));
    this.hide(autoHide, dispatch, delay);
  }

  hide(autoHide: boolean, dispatch: any, delay: number = 5000) {
    if (autoHide) {
      setTimeout(() => this.close(dispatch), delay)
    }
  }

  close(dispatch: any) {
    dispatch(setShow(false))
  }
}

const messageService = new MessageService();
export default messageService