import {Breakpoint, breakpointsDesc, getWidth} from "./breakpoint";

export function imageSizes(imageWidths: Partial<Record<Breakpoint, string>>): string {
    const sizes: string[] = [];
    for (const breakpoint of breakpointsDesc) {
        const imageWidth = imageWidths[breakpoint];
        if (imageWidth) {
            const viewportWidth = getWidth(breakpoint);
            sizes.push(viewportWidth > 0
                ? `(min-width: ${viewportWidth}px) ${imageWidth}`
                : imageWidth
            );
        }
    }
    return sizes.join(',');
}
