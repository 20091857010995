import i18next from "i18next";
import React from "react";

export default function ProductBadgeNew({product}: any) {
    const releaseDate = product.releaseDate ? new Date(product.releaseDate) : false;
    const date = new Date();
    date.setDate(date.getDate() - 30);

    if (!releaseDate || (releaseDate < date)) return <></>

    return (
        <div className={"product-badge bg-red me-2"}>
                <span className={"text-white"}>
                  {i18next.t('general.new').toString().toUpperCase()}
                </span>
        </div>
    )
}