import React from "react";

export default function ProductBadgeDiscount({product}: any) {
    const discount = getDiscount();

    function getDiscount() {
        if (product.pseudoPrice === product.price) return false;
        return Math.round(((product.pseudoPrice - product.price) / product.pseudoPrice) * 100);
    }

    if (!discount) return <></>

    return (
        <div className={"product-badge bg-black me-2"}>
            <span className={"text-white"}>{discount}%</span>
        </div>
    )
}