import React from "react";

export default function ProductBadgeTopSeller({product}: any) {
    if (!product.topseller) return <></>

    return (
        <div className={"product-badge bg-primary me-2"}>
            <span>
              <i className="fa-solid fa-thumbs-up text-white"></i>
            </span>
        </div>
    )
}