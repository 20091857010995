class ColorCardParser {
    // @tobias double json encoded articles column
    parse(articles: any, iteration: number = 0): any {
        if (!articles || iteration > 5) {
            return [];
        }
        const p = JSON.parse(articles);
        if (typeof p === 'string') return this.parse(p, iteration + 1);

        return p;
    }
}

const colorCardParser = new ColorCardParser();
export default colorCardParser;
