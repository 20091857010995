import apiClient from "../utils/apiClient";
import apiInternal from "../utils/apiInternal";
import cookieOptions from "../utils/cookieOptions";
import localStorageService from "./storage/localStorageService";

class WishlistService {
    async load() {
        try {
            const {data} = await apiClient.post('/api/wishlist/list');
            return data;
        } catch (e) {
            return [];
        }
    }

    reload(data: any) {
        if (data) return this.load();
        return false;
    }

    async addList(name: string) {
        const {data} = await apiClient.post('/api/wishlist/list/add', {name: name});
        return this.reload(data);
    }

    async removeList(id: number) {
        const {data} = await apiClient.post('/api/wishlist/list/remove', {id: id});
        return this.reload(data);
    }

    async mergeList(sourceId: number, targetId: number) {
        const {data} = await apiClient.post('/api/wishlist/list/merge', {id: sourceId, targetId});
        return this.reload(data);
    }

    async rename(id: number, name: string) {
        const {data} = await apiClient.post('/api/wishlist/list/rename', {id: id, name: name});
        return this.reload(data);
    }

    async addItem(id: number, orderNumber: string) {
        const {data} = await apiClient.post('/api/wishlist/item/add', {basketId: id, orderNumber: orderNumber});
        return this.reload(data);
    }

    async removeItem(id: number) {
        const {data} = await apiClient.post('/api/wishlist/item/remove', {id: id});
        return this.reload(data);
    }

    async moveItem(id: number, listId: number) {
        const {data} = await apiClient.post('/api/wishlist/item/move', {id, listId});
        return this.reload(data);
    }

    async cookie(): Promise<string> {
        const storageKey: string = 'wishlist';
        let sessionId: any = localStorageService.getItem(storageKey);
        if (sessionId) return sessionId;

        const result: any = await apiInternal().get('/api/v2/wishlist/cookie');
        sessionId = result.data;
        let expireDate: Date = new Date();
        expireDate.setFullYear(expireDate.getFullYear() + 1);

        localStorageService.setItem(storageKey, sessionId, cookieOptions(expireDate));
        return sessionId;
    }
}

const wishlistService = new WishlistService();
export default wishlistService