import i18next from "i18next";
import RoutePrefix from "../route/prefix";
import slugify from "../slugify";

class ProductLinkService {
    getLink(product: any) {
        if (product.article_path) return `/${i18next.language}/${product.article_path.toLowerCase()}`;
        if (product.link) return `/${i18next.language}/${product.link.toLowerCase()}`;
        return `/${i18next.language}/${RoutePrefix.product}/${slugify(this.getName(product))}-${product.detailId}`
    }

    getVariantLink(product: any) {
        if (product.link && product.link.length) {
            return `/${i18next.language}/${product.link.toLowerCase()}?id=${product.id}`;
        }

        return `/${i18next.language}/${RoutePrefix.product}/${slugify(this.getName(product))}-${product.id}`
    }

    getName(product: any) {
        try {
            return product.title ? product.title.toLowerCase() : product.name.toLowerCase();
        }
        catch(e) {
            return '';
        }
    }
}

const productLinkService = new ProductLinkService();
export default productLinkService