class RoutePrefix {
    static product = 'p';
    static productNotification = 'p/n';
    static listing = 'l';
    static search = 'q';
    static static = 's';
    static productLine = 'pl';
    static checkout = 'checkout';
    static content = 'c';
    static blog = 'bl';
    static brand = 'b';
    static brandListing = 'b/l';
    static account = 'account';
    static login = 'login';
    static register = 'register';
}

export default RoutePrefix